/** @jsx jsx */
import { useEffect } from 'react'
import { Link } from 'gatsby'
import {
  Box,
  Button,
  Container,
  useColorMode,
  Flex,
  Heading,
  jsx,
} from 'theme-ui'
import Text from '~/components/IntlComponents/Text'

const ErrorNotFoundPage = () => {
  const [colorMode, setColorMode] = useColorMode('OPE')
  useEffect(() => {
    setColorMode('OPE')
  })

  return (
    <Container>
      <Flex
        sx={{
          maxWidth: '1440px',
          flexDirection: ['column', 'row'],
          width: '100%',
          alignItems: 'center',
          margin: ' auto',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: ['100%', '30%'],
            order: ['2', '1'],
          }}
        >
          <Heading as="h1" variant="h2" sx={{ marginBottom: '2rem' }}>
            {'No Noodles Here :('}
          </Heading>
          <Button
            as={Link}
            to="/shop/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {'Shop Mac'}
          </Button>
        </Box>
        <Box
          sx={{
            width: ['100%', '65%'],
            overflow: 'hidden',
            order: ['1', '2'],
          }}
        >
          <Text
            sx={{
              alignSelf: 'center',
              display: 'block',
              fontSize: '25em',
              fontFamily: 'Sofia',
              transform: 'rotate(22deg)',
            }}
          >
            404
          </Text>
        </Box>
      </Flex>
    </Container>
  )
}

export default ErrorNotFoundPage
